/* eslint-disable */
import {lista, processar, pegaLista, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";

import {TratamentoSCH, getTratamentoSCH} from "../../search/TratamentosSCH"
import {EventBus} from '../../helpers/event-bus'
import {Tratamento} from '../../models/tratamentos'

const url = '/tratamento'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro(),
  tratamento: new Tratamento(),
}

const getters = {
  listaAllTratamentos: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },

  listaTratamentos: (state) => {
    return state.all
  },

  pegaTratamento: (state) => {
    return state.tratamento
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.addSearch(['titulo', 'ordem'] ,
      "%"+search+"%",
      'ilike'
    )
  },
  async getItem({commit}, id) {
    var filtro = getTratamentoSCH('tratamento_id', id, '=')
    const list = (await pegaLista(url, filtro)).data
    commit('TRATAMENTOS', list.data[0])
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', TratamentoSCH(perPage))
  },

  setTratamento({commit}, tratamento) {
    commit('SET_TRATAMENTO', tratamento)
  },

  async setAllTratamentos({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_TRATAMENTOS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }

  },

  async processarTratamento({commit}, {tratamento, acao,onSucesso,onErro}) {
    try {
      let res = await processar(url, tratamento, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Tratamentos')
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'TratamentoCadastrar')
      onErro()
    }
  }
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_TRATAMENTOS(state, tratamentos) {
    state.all = tratamentos
  },
  SET_TRATAMENTO(state, tratamento) {
    state.tratamento = tratamento
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
