/* eslint-disable */
import {lista, processar, pegaLista, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";

import {BannerSCH, getBaseSCH} from "../../search/BannerSCH"
import {EventBus} from '../../helpers/event-bus'
import {Banner} from '../../models/banner/banner'

const url = '/banner';

const state = {

  all: [],
  banner: new Banner(),
  carousel: 0,
  banner_item: [],
  banner_array: [],
  allLista: [],
  filtro: new Filtro()
};

const getters = {
  listaBanners: (state) => {
    return state.all !== undefined ? state.all : []
  },
  pegaCarousel: (state) => {
    return state.carousel
  },
  pegaBanner: (state) => {
    return state.banner
  },

  pegaBannerArray: (state) => {
    if (state.banner_array.length > 0) {
      state.banner_array.forEach(function (bl) {
        // console.log(bl);
        if (bl.imagens === undefined){
          bl.imagens =[]
        }
        if (!bl.is_video) {
          bl.imagens = bl.urls_imagem
        }

        if (bl.banner_imagem !== null){
        if (bl.banner_imagem.imagens === undefined){
          bl.banner_imagem.imagens =[]
          bl.banner_imagem.imagens = bl.banner_imagem.urls_imagem
        }
        }

      })
    }
    return state.banner_array
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  pegaBannerItem: (state) => {
    return state.banner_item
  }

};

const actions = {

  setCarousel({commit}, carousel) {
    sessionStorage.setItem('carousel', carousel)
    commit('SET_CAROUSEL', carousel)
  },
  setBannerArray({commit}, banner) {
    commit('SET_BANNER_ARRAY', banner)
  },
  setBanner({commit}, banner) {
    commit('SET_BANNER', banner)
  },

  async setAllListBanner({ commit }) {
    const list = (await lista(url + '/all')).data;
    commit('SET_ALL_LIST', list);
    EventBus.$emit('BANNERS_ALL_CONCLUIDO', true)
  },

  searchItem({commit}, search) {
    state.filtro.filters.addFilter('descricao' ,
      "%"+search+"%",
      'like'
    )
  },
  async getItem({commit}, id) {
    var filtro = getBaseSCH('banner_id', id, '=');
    const list = (await pegaLista(url, filtro)).data;
    commit('SET_BANNERS', list.data[0]);
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', BannerSCH(perPage))
  },
  setBannerItem({commit}, banner_item) {
    commit('SET_BANNER_ITEM', banner_item)
  },

  async setAllBanners({commit}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_BANNER_ARRAY', list.data)
      if (list.data.length > 0) {
        // console.log('\nsetAllBanners:', list.data[0].banner_imagem);
        // console.log('urls_imagem:', list.data[0].banner_imagem.urls_imagem);
        if(sessionStorage.getItem('carousel') !== null){

          var pos = parseInt(sessionStorage.getItem('carousel'))
          if (list.data[pos] === undefined || list.data[pos] === '' || list.data[pos] === null){
            commit('SET_BANNER_ITEM', list.data[0])
            commit('SET_CAROUSEL', 0 )
          } else {
            commit('SET_BANNER_ITEM', list.data[pos])
            commit('SET_CAROUSEL', pos )
          }

        } else {
          commit('SET_BANNER_ITEM', list.data[list.data.length-1])
          commit('SET_CAROUSEL', list.data.length-1)
        }
      }
      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }

  },
  async setAllBannersDesbloqueada({commit}) {
    const list = (await lista(url + '/desbloqueado')).data;
    commit('SET_BANNERS', list)
  },
  async processarBanner({commit}, {banner,acao, onSucesso, onErro}) {
    try {
      // console.log('processarBanner: ',banner);
      let res = await processar(url, banner, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      this.dispatch('banner/setAllBanners');
      onSucesso()

    } catch (error) {
      console.log(error)
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },
  processarExtras({commit}, {item, acao, tipo}) {
    if (acao === 'gravar') {
      commit('ADICIONA_' + tipo, item)
    } else if (acao === 'alterar') {
      commit('ALTERA_' + tipo, item)
    } else if (acao === 'remover') {
      commit('REMOVE_' + tipo, item)
    }
  },
};

const mutations = {

  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_BANNERS(state, banners) {
    state.all = banners
  },
  SET_BANNER(state, banner) {
    state.banner = banner

  },
  SET_CAROUSEL(state, carousel) {
    state.carousel = carousel
  },
  SET_BANNER_ARRAY(state, banner_array) {
    state.banner_array = banner_array
  },
  SET_BANNER_ITEM(state, banner) {
    state.banner_item = banner
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

  // BANNER_TEXTO
  REMOVE_BANNER_TEXTO(state, item) {
    let pos = state.banner.banners_textos.indexOf(item)
    state.banner.banners_textos.splice(pos, 1)
  },
  ALTERA_BANNER_TEXTO(state, item) {
    var c = state.banner.banners_textos.find(lista => lista.banner_texto_id === item.banner_texto_id)
    let pos = state.banner.banners_textos.indexOf(c)
    state.banner.banners_textos.splice(pos, 1, item)
  },
  ADICIONA_BANNER_TEXTO(state, item) {
    // state.banner.banner_texto.splice(state.banner.banner_texto.length, 1, item)
    // state.banner.banner_texto.push(item)
    // state.banner.banner_texto = item
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
