<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="table_iterator">

    <v-dialog v-model="loading" width="300">
      <v-card color="green" dark>
        <v-card-text>
          Carregando...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
      <v-data-iterator :items="itens" :items-per-page.sync="itensPerPage" :page.sync="page" :search="search"
                       :sort-by="sortBy.toLowerCase()" :sort-desc="sortDesc" hide-default-footer>
        <template v-slot:default="props">
          {{lista(props.items)}}
          <v-data-table fixed-header :headers="cabecalho" :items="props.items" :page.sync="page" :items-per-page="select"
                        hide-default-footer :search="search" :id="id" :expanded.sync="expanded"
                        class="elevation-1" @page-count="pageCount = $event" :hide-default-header="header"
                        @contextmenu:row="isshowContextMenu" :item-key="''+chave">
            <template v-slot:header v-if="header">
              <thead class="v-data-table-header">
              <tr class="v_tr" >
                <th role="columnheader" scope="col"
                    :aria-sort="item.aria_sort" :class="item.classe" @click="ordenando(item,index)" :id="'th_'+index"
                    v-for="(item, index) in listaCabecalho" :key="'cli'+index" :style="'text-align:'+ item.align">
                  <span>{{ item.text }}</span>
                  <i v-if="isSortable(item)" aria-hidden="true" style="font-size: 18px;"
                     :class="'v-icon notranslate v-data-table-header__icon mdi '+item.icone+' '" >
                  </i>
                </th>
              </tr>
              </thead>
            </template>

            <template v-slot:[n.slot]="{ item }" v-for="(n, index) in cabecalho" :id="index">
              <slot :name="n.slot_name" v-bind:item="item"></slot>
            </template>

            <template v-slot:[`item.actions`]="{item }">
              <div class="acao_tabela">
                <slot name="acao" v-bind:item="item"></slot>
              </div>
            </template>

            <template v-slot:[`item.expand`]="{item }">
              <v-btn icon color="primary" @click="expandir(item, item[chave])">
                <v-icon class="expand">expand_more</v-icon>
              </v-btn>
            </template>

            <template v-slot:expanded-item="{headers,item }" id="expandido">
              <td :colspan="headers.length" style="background: #fff;" class="expand_more">
                <slot name="expandido" v-bind:item="item"></slot>
              </td>
            </template>
          </v-data-table>

        </template>

        <template v-slot:no-data>
          <v-alert border="left" colored-border color="deep-orange accent-4" elevation="2">
            <h2 style="color: gray">Nada há exibir no momento</h2>
          </v-alert>
        </template>

        <template v-slot:footer>
          <v-card flat>
            <v-card-text>
              <v-container max-width="300">
                <div>
                  <v-row style="padding: 12px">
                    <v-divider style="margin: 0"></v-divider>
                  </v-row>
                  <v-row dense style="align-items: flex-end;padding: 5px">
                    <div>
                      <v-text-field :value="select" type="number" label="" hint="Itens por página" persistent-hint
                                    min="1" @input="select = parseInt($event, 10)"></v-text-field>
                    </div>
                    <v-spacer></v-spacer>
                    <v-pagination v-model="page" :length="totalPaginas" :total-visible="7" color="red"/>
                    <v-spacer></v-spacer>
                    <v-btn v-if="showAddButton" tile color="primary" @click="goToAdd" :disabled="disabledAddButton">
                      <v-icon>add</v-icon>
                      NOVO
                    </v-btn>
                    <slot name="slot_footer"></slot>
                  </v-row>
                </div>
              </v-container>
            </v-card-text>
          </v-card>
        </template>
      </v-data-iterator>
  </div>
</template>

<script>
/* eslint-disable */
  import {EventBus} from "../helpers/event-bus";
  import {mapGetters} from "vuex";
  import paginacao from '../helpers/paginacao'

  export default {
    mixins: [paginacao],
    name: "TableIterator",
    props: {
      cabecalho: {type: Array, default: () => []},
      itens: {type: Array, default: () => []},
      id: {default: 'tabela'},
      PorPage: {default: 10},
      totalPaginas: {default: 1},
      setPagina: {default: 1},
      showItemPerPag: {type: Boolean, default: true},
      chave: {default: ''},
      // loading: {type: Boolean, default: false},
      header: {type: Boolean, default: false},
      showexpand: {type: Boolean, default: false},
      showAddButton: {type: Boolean, default: true},
      showContextMenu: {type: Boolean, default: false},
      disabledAddButton: {type: Boolean, default: false},
      emitir: {type: Boolean, default: false},
    },
    data: () => ({
      lista_cab: [],
      expanded: [],
      exp: '',
      loading: false,
      singleExpand: false,
      select: 10,
      page: 1,
      sortBy: '',
      sortDesc: false,
      itensPerPage: 10,
    }),
    methods: {
      getClasseExpander(){
        return this.expanded.length > 0 ? 'expandido' : ''
      },
      validarCampo(campo) {
        if (campo !== undefined && campo !== null && campo !== '') {
          return true
        } else {
          return false
        }
      },
      isSortable(item) {
        if (item.hasOwnProperty('sortable')) {
          return item.sortable
        } else {
          return true
        }
      },
      setClasse(item) {
        let classe = 'text-left '

        if(this.validarCampo(item.align)){
          classe = 'text-'+item.align + ' '
        }

        if (item.hasOwnProperty('sortable')) {
          classe += item.sortable ? 'sortable ' : ' '
        } else {
          classe += 'sortable '
        }
        return classe
      },
      ordenando(item,index) {
        let c = JSON.parse(JSON.stringify(item.classe))

        if (this.isSortable(item)) {
          this.loading= true
          if (item.aria_sort === 'none') {
            item.aria_sort = 'ascending'

            if (item.classe.match(/active desc/) !== null) {
              c = item.classe.replace('active desc', '')
            }

            if (item.classe.match(/active asc/) === null) {
              c += 'active asc'
            }
          } else if (item.aria_sort === 'ascending') {
            item.aria_sort = 'descending'
            if (item.classe.match(/active asc/) !== null) {
                c = item.classe.replace('active asc', '')
            }
            if (item.classe.match(/active desc/) === null) {
              c += 'active desc'
            }

          } else if (item.aria_sort === 'descending') {
            item.aria_sort = 'none'

            if (item.classe.match(/active asc/) !== null) {
              c = item.classe.replace('active asc', '')
            }
            if (item.classe.match(/active desc/) !== null) {
              c = item.classe.replace('active desc', '')
            }
            c += ''
          }
        }

        item.classe = c

        this.lista_cab[index] = item

        this.ordenar(item.value, item.aria_sort)
      },

      isshowContextMenu(event, item) {
        if (this.showContextMenu) {
          this.$emit('context', {event: event, item: item})
        } else {
          event.preventDefault()
        }
      },
      expandir(item, id) {


        if (this.singleExpand && this.expanded.length > 0) {
          this.expanded = this.exp === id ? [] : [item]
          this.singleExpand = this.exp === id
        } else if (this.singleExpand && this.expanded.length <= 0) {
          this.expanded = [item]
        } else if (!this.singleExpand && this.expanded.length > 0) {
          this.singleExpand = true
          this.expanded = [item]
        } else if (!this.singleExpand && this.expanded.length <= 0) {
          this.singleExpand = true
          this.expanded = [item]
        }

        // this.expandiu = this.singleExpand
        this.exp = id
      },
      goToAdd() {
        if (this.emitir) {
          this.$emit('novo','')
        } else {
          EventBus.$emit('CLICK_BTN_ADD')
        }
      },
      lista(item) {
        this.$emit('item', item)
      },
      updateItemsPerPage(number) {
        this.itensPerPage = number
        this.$emit('por_paginas', parseInt(number))
      },

      ordenar(id, ordem) {

        let direcao = 'asc'

        if (!this.header) {
          if (ordem !== 'none' && ordem !== null) {
            direcao = ordem === 'ascending' ? 'asc' : 'desc'
            let ordenar = {
              campo: id,
              direction: direcao
            }
            this.$emit('ordem', ordenar)
          }
        } else {
          let orde = {
            campo: '',
            direction: ''
          }

          if (ordem !== 'none' && ordem !== null) {
            direcao = ordem === 'ascending' ? 'asc' : 'desc'
            orde = {
              campo: id,
              direction: direcao
            }
            this.$emit('ordem', orde)
          } else {
            orde = {
              campo: id,
              direction: 'asc'
            }
            this.$emit('ordem', orde)
          }
        }
      },
      inicializar() {
        let tabela = document.getElementById(this.id)
        let elemento = document.getElementsByTagName('th')

        let _this = this
        if (tabela !== null) {
          for (let i = 0; i < elemento.length; i++) {
            if (_this.cabecalho[i].value !== undefined || _this.cabecalho[i].value !== '') {
              elemento[i].id = _this.cabecalho[i].value
              let classe = elemento[i].className
              let is_sotrable = classe.match(/sortable/) !== null
              if (is_sotrable) {
                elemento[i].addEventListener('click', function (e) {
                  let id = ''
                  let ariaSort = ''
                  if (e.target.id !== null && e.target.id !== '' && e.target.id !== undefined) {
                    id = e.target.id
                    ariaSort = e.target.ariaSort
                  } else {
                    id = e.target.parentNode.id
                    ariaSort = e.target.parentNode.ariaSort
                  }
                  _this.ordenar(id, ariaSort)
                })
              }
            }
          }
        }
      }
    },
    computed: {
      // ...mapGetters('configuracao', {tema: 'pegarTema'}),
      listaCabecalho() {
        let cabecalho = JSON.parse(JSON.stringify(this.cabecalho))
        for(let i = 0; i< cabecalho.length; i++){
          cabecalho[i].classe = this.setClasse(cabecalho[i])
          cabecalho[i].icone = 'mdi-arrow-up'
          cabecalho[i].aria_sort = 'none'
        }
        this.lista_cab = cabecalho
        return cabecalho
      },
      getTotalPagina() {
        return Math.ceil(this.itens.length / this.select)
      },
      ...mapGetters('geral', {search: 'getSearch'}),

    },
    watch: {
      select: function () {
        this.select = parseInt(this.select) <= 0 ? 1 : this.select
        this.page = 1
        if (this.select > 0) {
          this.updateItemsPerPage(parseInt(this.select))
        } else {
          this.updateItemsPerPage(10)
        }

      },
      setPagina: function () {
        if (this.setPagina) {
          this.page = 1
        }
      },
      page: function () {
        this.page = isNaN(this.page) ? 0 : this.page
        this.loading = true
        this.$emit('paginas', parseInt(this.page))
      },
    },
    created() {
    },
    mounted() {
      EventBus.$on('SHOW_LISTA', (sucesso) => {
        // console.log('SHOW_LISTA: ', sucesso);
        this.loading = false
        if (sucesso) {
          // if (!this.header) {
          //   let _this = this
          //   let inter = setInterval(function () {
          //     _this.inicializar()
          //     clearInterval(inter)
          //   }, 5000)
          // }
        }
      })

    },
    beforeDestroy() {
      EventBus.$off('SHOW_LISTA')
    },

  }
</script>

