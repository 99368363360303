/* eslint-disable */
import {pegaDados, processar} from '../../services/GenericAPI'
import {Filtro, Where} from "../../models/filtro/filtro";

import {LeadsSCH} from "../../search/LeadsSCH"
import {EventBus} from '../../helpers/event-bus'

const url = '/lead'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro(),
}

const getters = {
  listaAllLeads: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },

  listaLeads: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.addSearch(['nome', 'email'] ,
      "%"+search+"%",
      'ilike'
    )
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', LeadsSCH(perPage))
  },

  async setAllLeads({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_LEADS', list)
      // commit('SET_LEADS', [])
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }

  },

  async processarLeitore({commit}, {leitor, acao}) {
    try {
      let res = await processar(url, leitor, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('leitor/setAllLeads')
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Leads')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'LeitoreCadastrar')
    }
  }
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_LEADS(state, leitores) {
    state.all = leitores
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
