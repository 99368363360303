/* eslint-disable */
import {lista, processar, pegaLista, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";

import {DoencaSCH, getDoencaSCH} from "../../search/DoencaSCH"
import {EventBus} from '../../helpers/event-bus'
import {Doenca} from '../../models/doenca'

const url = '/doenca'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro(),
  doenca: new Doenca(),
}

const getters = {
  listaAllDoencas: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },

  listaDoencas: (state) => {
    return state.all
  },

  pegaDoenca: (state) => {
    return state.doenca
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListDoenca({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('DOENCAS_ALL_CONCLUIDO', true)
  },

  searchItem({commit}, search) {
    state.filtro.filters.addSearch(['titulo', 'ordem'] ,
      "%"+search+"%",
      'ilike'
    )
  },
  async getItem({commit}, id) {
     var filtro = getBaseSCH('doenca_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
      commit('DOENCAS', list.data[0])
     EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', DoencaSCH(perPage))
  },
  setDoenca({commit}, doenca) {
     commit('SET_DOENCA', doenca)
  },
  // Pag
  async filtarDoenca({commit}, filtro) {
    const list2 = (await filtrando(url, filtro)).data
    commit('SET_DOENCAS', list2)
  },

  async setAllDoencas({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_DOENCAS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
  async setAllDoencasDesbloqueada({commit}) {
    const list = (await lista(url + '/desbloqueado')).data
    commit('SET_DOENCAS', list)
  },
  async processarDoenca({commit}, {doenca, acao,onSucesso,onErro}) {
    try {
      let res = await processar(url, doenca, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Doenca')

      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'DoencaCadastrar')
      onErro()
    }
  }
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_DOENCAS(state, doencas) {
      state.all = doencas
  },
  SET_DOENCA(state, doenca) {
      state.doenca = doenca
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
