/* eslint-disable */
import {lista, processar, pegaLista,pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";

import {PerfilSCH} from "../../search/PerfilSCH"
import {EventBus} from '../../helpers/event-bus'
import {Perfil} from "../../models/perfil";

const url = '/perfil'

const state = {
  all: [],
  filtro: new Filtro(),
  perfil: new Perfil(),
}

const getters = {
  listaPerfil: (state) => {
    return state.perfil !==null && state.perfil !==undefined ? state.perfil : new Perfil()
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListPerfil({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('PERFIL_ALL_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', PerfilSCH(perPage))
  },

  setPerfil({commit}, perfil) {
    commit('SET_PERFIL', perfil)
  },

  async setAllPerfil({commit}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_PERFIL', list.data[0])
      EventBus.$emit('PERFIL_PROCESSADO', true)
    } catch (error) {
      EventBus.$emit('PERFIL_PROCESSADO', false)
    }

  },

  async processarPerfil({commit}, {perfil, acao}) {
    try {
      let res = await processar(url, perfil, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      this.dispatch('perfil/setAllPerfil')
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Perfil')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'Perfil')
    }
  }
}

const mutations = {
  SET_PERFIL(state, perfil) {
      state.perfil = perfil
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
