<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-data-iterator hide-default-footer :items="itens" :items-per-page.sync="itemsPorPage"
                     :page.sync="page">
      <template v-slot:default="props">
        <v-card class="tabela">
          <v-data-table fixed-header hide-default-footer :items="props.items" :dense="dense">
            {{lista(props.items)}}
            <template v-slot:header>
              <thead>
              <tr class="tr_header">
                <th v-for="(item, index) in cabecalho" :key="'cli'+index" :style="'text-align:'+ item.align">
                  <v-btn small text @click="ordenar(index, 'icon_'+index, item.value)" class="btn_header">
                    {{item.text}}
                    <v-icon right style="color:gray" small :id="'icon_'+index"></v-icon>
                  </v-btn>
                </th>
              </tr>
              </thead>
            </template>

            <template v-slot:body>
              <tbody>
              <slot></slot>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </template>

      <template v-slot:no-data>
        <v-alert type="warning">
          <div style="text-align: center">
            <h4>Nada relativo a {{tabela}} foi encontrado(a). </h4>
          </div>
        </v-alert>
      </template>

      <template v-slot:footer>
        <v-row wrap>
          <v-col cols="12" sm="8" md="9" lg="10">
            <v-container max-width="300">
              <v-pagination v-model="page" :length="totalPaginas" :total-visible="7"></v-pagination>
            </v-container>
          </v-col>
          <v-col cols="12" sm="4" md="3" lg="2">
            <div style="float: right" v-show="showItemPerPag">
              <v-text-field dense style="width: 100px;"  v-model="select" label="" hint="Itens por pagina" persistent-hint
                            type="number"></v-text-field>
            </div>
          </v-col>
        </v-row>

      </template>
    </v-data-iterator>
  </div>

</template>

<script>
/* eslint-disable */
  import paginacao from '../helpers/paginacao'

  export default {
    name: "ProTable",
    mixins: [paginacao],
    props: ['itens', 'cabecalho', 'dense', 'itemsPorPage', 'tabela', 'totalPaginas', 'setPagina', 'showItemPerPag'],
    data: () => ({
      select: 15,
      page: 1,
      pageCount: 1,
      showCont: false
    }),
    methods: {
      lista(item) {
        this.$emit('item', item)
      },
      getRetornoIcon(campo, valor1, valor2, valor3) {
        if (campo === valor1) {
          return valor2
        } else if (campo === valor2) {
          return valor3
        } else {
          return valor1
        }
      },
      ordenar(ordem, id, acao) {
        var elemento = document.getElementsByClassName('tr_header')
        var children = elemento.item(0).children
        for (var i = 0; i < children.length; i++) {
          var id_icon = children[i].getElementsByClassName('v-btn__content').item(0).getElementsByTagName('i').item(0).id
          if (id_icon === id) {
            if (acao !== 'acao' && acao !== 'imagem' && acao !== 'bloquear_ordenacao') {
              var icone = children[i].getElementsByClassName('v-btn__content').item(0).getElementsByTagName('i').item(0).innerText
              var text_icon = this.getRetornoIcon(icone, 'expand_less', 'expand_more', '')
              children[i].getElementsByClassName('v-btn__content').item(0).getElementsByTagName('i').item(0).innerText = text_icon
              console.log(acao)
              var ordenar = {
                campo: acao,
                direction: text_icon === 'expand_less' ? 'asc' : text_icon === 'expand_more' ? 'desc' : 'asc'
              }
              this.$emit('ordem', ordenar)
            }
          } else {
            children[i].getElementsByClassName('v-btn__content').item(0).getElementsByTagName('i').item(0).innerText = ''
          }
        }
      }
    },
    watch: {
      select: function () {
        this.select = parseInt(this.select) === 0 ? 1 : this.select
        this.$emit('por_paginas',  parseInt(this.select))
      },
      setPagina: function () {
        if (this.setPagina) {
          this.page = 1
        }
      },
      page: function () {
        this.page =  isNaN(this.page) ? 0 : this.page

        this.$emit('paginas', this.page)
      }
    }
  }
</script>

<style scoped>

</style>
