/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import geral from './modules/geral'

import configuracao from './modules/configuracoes'
import usuario from './modules/usuarios'
import blog from './modules/blog'
import doenca from './modules/doenca'
import perfil from './modules/perfil'
import instagram from './modules/instagram'
import banner from './modules/banner2'
import local_atendimento from './modules/local_atendimento'
import banner_control_modal from './modules/banner_control_modal'
import tratamento from './modules/tratamentos'
import leads from "./modules/leads";
import livro from "./modules/livro";


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    blog,
    leads,
    geral,
    livro,
    perfil,
    banner,
    doenca,
    usuario,
    tratamento,
    instagram,
    configuracao,
    local_atendimento,
    banner_control_modal
  }
})
