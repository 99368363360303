<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-data-iterator hide-default-footer :items="itens" :items-per-page.sync="itemsPerPage"
                      :page.sync="page">
      <template v-slot:default="props">
        <v-card class="tabela">
          <v-data-table fixed-header hide-default-footer :items="props.items" :dense="dense">
            {{lista(props.items)}}
            <template v-slot:header>
              <thead>
              <tr class="tr_header" v-if="com_orbem">
                <th v-for="(item, index) in cabecalho" :key="'cli'+index" :style="'text-align:'+ item.align">
                  <v-btn small text @click="ordenar(index, 'icon_'+index, item.value)" class="btn_header">
                    {{item.text}}
                    <v-icon right style="color:gray" small :id="'icon_'+index"></v-icon>
                  </v-btn>
                </th>
              </tr>

              <tr v-else>
                <th v-for="(item, index) in cabecalho" :key="'cli'+index" :style="'text-align:'+ item.align">
                  {{item.text}}
                  <v-icon right style="color:gray" small :id="'icon_'+index"></v-icon>
                </th>
              </tr>

              </thead>
            </template>

            <template v-slot:body>
              <tbody>
              <slot></slot>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </template>

      <template v-slot:no-data>
        <v-alert type="warning">
          <div style="text-align: center">
            <h4>Nada relativo a {{tabela}} foi encontrado(a). </h4>
          </div>
        </v-alert>
      </template>

      <template v-slot:footer>
        <v-row wrap>
          <v-col cols="12" sm="8" md="9" lg="10">
            <v-container max-width="300">
              <v-pagination v-model="page" :length="getTotalPagina" :total-visible="7"></v-pagination>
            </v-container>
          </v-col>
          <v-col cols="12" sm="4" md="3" lg="2">
              <v-text-field dense style="width: 100px;"  v-model="select" label="" hint="Itens por pagina" persistent-hint
                            type="number"></v-text-field>
          </v-col>
        </v-row>

      </template>

    </v-data-iterator>
  </div>

</template>

<script>
/* eslint-disable */
  export default {
    name: "ProTable",
    props: ['itens', 'cabecalho', 'dense', 'tabela', 'com_orbem'],
    data: () => ({
      select: 15,
      page: 1,
      pageCount: 1,
      itemsPerPage: 15,
    }),
    methods: {
      lista(item) {
        this.$emit('item', item)
      },
      getRetornoIcon(campo, valor1, valor2, valor3) {
        if (campo === valor1) {
          return valor2
        } else if (campo === valor2) {
          return valor3
        } else {
          return valor1
        }
      },
      ordenar(ordem, id, acao) {
        var elemento = document.getElementsByClassName('tr_header')
        var children = elemento.item(0).children
        for (var i = 0; i < children.length; i++) {
          var id_icon = children[i].getElementsByClassName('v-btn__content').item(0).getElementsByTagName('i').item(0).id
          if (id_icon === id) {
            if (acao !== 'acao' && acao !== 'imagem' && acao !== 'bloquear_ordenacao') {
              var icone = children[i].getElementsByClassName('v-btn__content').item(0).getElementsByTagName('i').item(0).innerText
              var text_icon = this.getRetornoIcon(icone, 'expand_less', 'expand_more', '')
              children[i].getElementsByClassName('v-btn__content').item(0).getElementsByTagName('i').item(0).innerText = text_icon
              // console.log(acao)
              var ordenar = {
                campo: acao,
                direction: text_icon === 'expand_less' ? 'asc' : text_icon === 'expand_more' ? 'desc' : 'asc'
              }
              this.$emit('ordem', ordenar)
            }
          } else {
            children[i].getElementsByClassName('v-btn__content').item(0).getElementsByTagName('i').item(0).innerText = ''
          }
        }
      }
    },
    computed: {
      getTotalPagina() {
        return Math.ceil(this.itens.length / this.itemsPerPage)
      }
    },
    watch: {
      select: function () {
       if (this.select === '' || this.select === undefined || this.select === null || this.select === 0) {
          this.select = 1
        }
        this.itemsPerPage = parseInt(this.select)
      },
    }
  }
</script>
