/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from './views/Dash.vue'
import Login from './views/Login.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/admin',
      name: 'home',
      component: Dash,
      meta: 'Admin',
      children: [
        // PRINCIPAL
        {
          path: '/principal',
          name: 'Principal',
          meta: 'Principal',
          component: () => import(/* webpackChunkName: "about" */ './views/Home/Home.vue')
        },

        // CADASTRAR
        {
          path: '/tratamentos',
          name: 'Tratamentos',
          meta: 'Tratamentos',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/Tratamentos/Tratamento')
        },
        {
          path: '/tratamento_cadastrar',
          name: 'TratamentoCadastrar',
          meta: 'Tratamento',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/Tratamentos/TratamentoCadastrar')
        },
        {
          path: '/doenca',
          name: 'Doenca',
          meta: 'Tipo de Dor',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/doenca/Doenca')
        },
        {
          path: '/doenca_cadastrar',
          name: 'DoencaCadastrar',
          meta: 'Doenca',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/doenca/DoencaCadastrar')
        },
        {
          path: '/blog',
          name: 'Blog',
          meta: 'Blog',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/blog/Blog')
        },
        {
          path: '/blog_cadastro',
          name: 'BlogCadastrar',
          meta: 'Blog',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/blog/BlogCadastrar')
        },
        {
          path: '/url_instagram',
          name: 'Instagram',
          meta: 'Instagram',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/Instagram/Instagram.vue')
        },
        {
          path: '/galeria',
          name: 'LocalAtendimento',
          meta: 'LocalAtendimento',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/LocalAtendimento/LocalAtendimento.vue')
        },
        {
          path: '/perfil',
          name: 'Perfil',
          meta: 'Perfil',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/perfil/Perfil.vue')
        },
        // CONFIGURAÇÕES
        {
          path: '/sobre',
          name: 'Sobre',
          meta: 'Sobre',
          component: () => import(/* webpackChunkName: "about" */ './views/Configuracao/Sobre.vue')
        },

        {
          path: '/tema',
          name: 'Tema',
          meta: 'Tema',
          component: () => import(/* webpackChunkName: "about" */ './views/Configuracao/Tema/Tema.vue')
        },
        {
          path: '/usuario',
          name: 'Usuário',
          meta: 'Usuários',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/usuarios/Usuario.vue')
        }, {
          path: '/leitores',
          name: 'Leitores',
          meta: 'Leitores',
          component: () => import(/* webpackChunkName: "about" */ './views/Leads.vue')
        },
        {
          path: '/livro',
          name: 'Livro',
          meta: 'Livro',
          component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/Livro/Livro.vue')
        },

      ]
    },
    // BANNER
    {
      path: '/banner',
      name: 'Banner',
      component: () => import(/* webpackChunkName: "about" */ './views/Cadastro/Banner2/Banner2.vue')
    },
    // 404
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "about" */ './views/404.vue')
    }
  ]
})
