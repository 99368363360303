<template>
  <div class="app-login">
    <v-card flat>
      <v-toolbar class="app-login topback" style="background: #000 !important" dark extended>
      </v-toolbar>

      <v-row pb-2 align="center" justify="center">

        <v-col cols="12" sm="8" offset-sm2 align-self="center">
          <v-card class="card--flex-toolbar" style="background: #EEEEEE !important;">
            <v-card-text>
              <v-layout row wrap>
                <v-col cols="12" sm="12" md="6">
                  <div style="text-align: center">
                    <div class="logo">
                      <img src="../assets/img/logo_completa-V.svg" alt="">
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <div class="formLogin">
                    <h1 class="login">Login</h1>
                    <v-form v-model="valid">
                      <v-container>
                        <v-layout row wrap>
                          <v-col cols="12">
                            <v-text-field outlined class="inp" v-model="user.email" label="Usuário"
                                          id="usuario"
                                          prepend-inner-icon="person"
                                          v-on:keyup="PressEnter(user.password, user.email)"
                                          required></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field outlined class="inp" v-model="user.password" label="Senha"
                                          prepend-inner-icon="lock"
                                          type="password"
                                          id="password"
                                          v-on:keyup="PressEnter(user.password, user.email)"
                                          required></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <div style="text-align: right;">
                              <v-btn @click="efetuaLogin(user)" color="primary" :loading="spinner2">Entrar</v-btn>
                            </div>
                          </v-col>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </div>
                </v-col>
              </v-layout>
              <v-layout row wrap>
                <v-col cols="12">
                  <div class="logoprod">
                    <img src="../assets/img/logo.png" alt="">
                  </div>
                </v-col>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>


    <!-- Dialog que vai exibir a lista de erros caso aja uma validação -->
    <v-dialog v-model="dialogError" max-width="500">
      <v-card v-if="objMsgError !== null">
        <v-card-title class="headline">{{ objMsgError.message }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div v-if="objMsgError.details !== null">
            <ul :key="index" v-for="(message, index) in objMsgError.details">
              <li> {{ message.mensagem }}</li>
            </ul>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="toggleDialogError()">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-footer :fixed="fixed" app dark class="footerLogin" >
        <div style="text-align: center; width: 100%">
          <span style="font-size: 0.7rem">Copyrigth &copy; 2019 <a href="http://www.prodatanet.com.br/">Prodata Informática</a>. Todos os direitos reservados.</span>
        </div>
    </v-footer>
  </div>
</template>

<script>
/* eslint-disable */
  import {EventBus} from '../helpers/event-bus'
  import miscMixin from "../helpers/misc";
  import {mapActions, mapGetters, mapState} from "vuex";
  import router from '../router'

  export default {
    mixins: [miscMixin],
    data: () => ({
      fixed: true,
      user: [],
      spinner2: false,
      valid: false,
      name: '',
      nameRules: [
        v => !!v || 'Digite o usuario',
        v => v.length <= 1 || 'O nome deve ter mais de 1 letra'
      ],
      senha: '',
      senhaRules: [
        v => !!v || 'Digite a senha',
        v => v.length <= 1 || 'A senha deve ter mais de 1 letra'
      ]
    }),

    methods: {
      ...mapActions('usuario', ['autenticarUsuario']),
      ...mapActions('geral', ['setModal', 'toggleDialogError', 'toggleSnackSuccess']),

      efetuaLogin(usuario) {

        this.spinner2 = true
        var array = {
          email: this.retorno(this.validarCampo(usuario.email), true, usuario.email, ""),
          password: this.retorno(this.validarCampo(usuario.password), true, usuario.password, "")
        }
        this.autenticarUsuario(array)
        // router.push({name: 'Principal'}).catch(err => {})
      },
      PressEnter(senha, usuario) {
        var _this = this
        var user = document.getElementById('usuario')
        var senhaId = document.getElementById('password')

        user.addEventListener('keypress', function (e) {
          if (e.keyCode === 13 || e.which === 13) {
            if (!_this.validarCampo(usuario)) {
              user.focus()
            } else if (!_this.validarCampo(senha)) {
              senhaId.focus()
            } else {
              _this.efetuaLogin(_this.user)
            }
          }
        }, false)
        senhaId.addEventListener('keypress', function (e) {
          if (e.keyCode === 13 || e.which === 13) {
            if (!_this.validarCampo(senha)) {
              senhaId.focus()
            } else {
              if (!_this.validarCampo(usuario)) {
                user.focus()
              } else {
                _this.efetuaLogin(_this.user)
              }
            }
          }
        }, false)
      },
    },
    computed: {
      ...mapState('geral', {
        dialogError: 'dialogError',
        objMsgError: 'objMsgError',
        snackSuccess: 'snackSuccess',
        msgSuccess: 'msgSuccess'
      }),
    },
    mounted() {
      EventBus.$on('USUARIO_AUTENTICADO', (sucesso) => {
        this.spinner2 = false
        if (sucesso) {
          var inter = setInterval(function () {
            router.push({name: 'Principal'}).catch(err => {})
            clearInterval(inter)
          }, 1000)
        }
      })

      // this.user.email = 'admin@prodata.com.br'
      // this.user.password = 'Pro2019'
    }
  }
</script>
<style scoped>
  .topback {
    background: rgba(0, 0, 0, 0.7) !important;
    height: 200px !important;
  }

  .card--flex-toolbar {
    margin-top: -100px;
  }

  .formLogin {
    width: 90%;
    margin: 0 auto;
    padding: 2%;
  }

  .logo {
    text-align: center;
    padding-top: 5%;
  }

  .logo img {
    width: 70%;
  }

  .logoprod {
    text-align: right;
    padding-right: 3%;
  }

  .logoprod img {
    width: 10%;
  }

  .br {
    display: block;
  }

  @media screen and (min-width: 200px) and (max-width: 450px) {
    .card--flex-toolbar {
      margin-top: -200px !important;
    }

    .login {
      font-size: 1.5rem;
    }

    .br {
      display: none;
    }

    .logoprod {
      padding-right: 15%;
    }

    .logoprod img {
      width: 40%;
    }
  }

  @media screen and (min-width: 451px) and (max-width: 599px) {
    .card--flex-toolbar {
      margin-top: -190px !important;
    }

    .br {
      display: none;
    }

    .logoprod {
      padding-right: 10%;
    }

    .logoprod img {
      width: 30%;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 959px) {
    .card--flex-toolbar {
      margin-top: -110px !important;
    }

    .logoprod {
      padding-right: 10%;
    }

    .logoprod img {
      width: 30%;
    }
  }

  @media screen and (min-width: 960px) and (max-width: 1600px) {
    .topback {
      height: 300px
    }

    .card--flex-toolbar {
      margin-top: -110px !important;
    }

    .logo {
      text-align: center;
      padding-top: 15%;
    }

    .logo img {
      width: 90%;
    }
  }

  @media screen and (min-width: 1601px) and (max-width: 1800px) {
    .topback {
      height: 300px
    }

    .card--flex-toolbar {
      margin-top: -110px !important;
    }

    .logo {
      text-align: center;
      padding-top: 15%;
    }

    .logo img {
      width: 90%;
    }
  }

  @media screen and (min-width: 1801px) and (max-width: 1919px) {
    .topback {
      height: 420px
    }

    .card--flex-toolbar {
      margin-top: -150px !important;
    }

    .logo {
      text-align: center;
      padding-top: 15%;
    }

    .logo img {
      width: 90%;
    }
  }

  @media screen and (min-width: 1920px) {
    .topback {
      height: 350px
    }

    .card--flex-toolbar {
      margin-top: -150px !important;
    }

    .logo {
      text-align: center;
      padding-top: 15%;
    }

    .logo img {
      width: 90%;
    }

    .logoprod {
      padding-right: 10%;
    }

    .logoprod img {
      width: 15%;
    }
  }


</style>
