/* eslint-disable */
import {lista, processar, pegaLista,pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";

import {LivroSCH} from "../../search/LivroSCH"
import {EventBus} from '../../helpers/event-bus'
import {Livro} from '../../models/livro'

const url = '/livro'

const state = {
  all: [],
  filtro: new Filtro(),
  livro: new Livro(),
}

const getters = {
  listaLivro: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  pegaLivro: (state) => {
    return state.livro !== undefined ? state.livro : new Livro()
  }
}

const actions = {
  async setAllListLivro({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('DOENCAS_ALL_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', LivroSCH(perPage))
  },
  async setAllLivro({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_LIVRO', list.data[0])
      onSucesso()
    } catch (error) {
      onErro()
    }

  },

  async processarLivro({commit}, {livro, acao, onSucesso,onErro}) {
    try {
      let res = await processar(url, livro, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      EventBus.$emit('PROCESSO_CONCLUIDO', true, true, 'Livro')
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'Livro')
      onErro()
    }
  }
}

const mutations = {
  SET_LIVRO(state, livro) {
      state.livro = livro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
